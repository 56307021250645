
















































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Image, Sticky, Swipe, SwipeItem } from "vant";
import { Route, NavigationGuardNext } from "vue-router";
import RankNavBar from "@/components/RankNavbar.vue";

@Component({
  components: {
    RankNavBar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
})
export default class RankDetail extends Mixins(Mixin) {
  aggregateBillboardDtos: Array<good.BillboardSquareDto> = [];
  billboardInfoDtos: Array<good.BillboardInfoDto> = [];
  title = "";
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next(() => {
      if (from.path == "/selectcity") {
        delete to.query.cityName;
        delete to.query.cityCode;
        next({
          path: "/rank-list",
          replace: true,
        });
      } else {
        next();
      }
    });
  }
  @Watch("$route")
  changeRoute(): void {
    let cityName = String(this.$route.query.cityName);
    let cityCode = String(this.$route.query.cityCode).match(/code/g)
      ? String(this.$route.query.cityCode).split("code")[0]
      : String(this.$route.query.cityCode);
    if (cityName != "undefined" && cityCode != "undefined") {
      this.LocationModule.SET_city_PERSIST({ cityName, cityCode });
      this.LocationModule.SET_isChangeCity_PERSIST(true);
    }
    this.recieveRankDetail();
  }
  mounted(): void {
    let cityName = String(this.$route.query.cityName);
    let cityCode = String(this.$route.query.cityCode).match(/code/g)
      ? String(this.$route.query.cityCode).split("code")[0]
      : String(this.$route.query.cityCode);
    if (cityName != "undefined" && cityCode != "undefined") {
      this.LocationModule.SET_city_PERSIST({ cityName, cityCode });
      this.LocationModule.SET_isChangeCity_PERSIST(true);
    }
    this.recieveRankDetail();
  }

  created(): void {
    this.recieveRankDetail();
    // chrome|火狐浏览器
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  recieveRankDetail(): void {
    this.$api.goodApi.billboard.getBillboardSquare(({ data }) => {
      this.aggregateBillboardDtos = data.aggregateBillboardDtos
        ? data.aggregateBillboardDtos
        : [];
      this.billboardInfoDtos = data.billboardInfoDtos
        ? data.billboardInfoDtos
        : [];
    });
  }
  goToDetail(billboardId: unknown, index: string): void {
    this.$router.push(`/rank-detail/${billboardId}/${index}`);
  }
}
